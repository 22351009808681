import React from "react";
import css from "./HomeProducts.module.scss";
import path from "../../util/path";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ArrowIcon from "../../assets/icons/arrow-more.svg";

const HomeProducts = (props) => {
  const { t, i18n } = useTranslation();
  const { lists } = props;

  return (
    <div className={css.homeProds}>
      <div className={css.homeTitle}>
        <div className={css.titleLeft}>
          <h3>
            Spring<span>/</span>Summer  <span>25</span>
          </h3>
        </div>
        <Link to={{ pathname: "/search", query: { collectionId: 104 } }}>
          {t("home.see all")}
        </Link>
      </div>
      {lists?.map((app, indx) => (
        <div key={indx} className={css.collWrap}>
          <div className={css.appTitle}>
            <div className={css.appTitleCont}>
              <h5>
                {i18n.language.includes("it")
                  ? app.name
                  : i18n.language.includes("en")
                  ? app.nameEn
                  : i18n.language.includes("fr")
                  ? app.nameFr
                  : null}
              </h5>
              <Link
                to={{ pathname: "/search", query: { applicationId: app.id } }}
              >
                <img src={ArrowIcon} alt="" />
              </Link>
            </div>
            <a
              className={css.downloadC}
              target="_blank"
              rel="noopener noreferrer"
              href={
                path +
                `storage/catalog/Attilio_Imperiali_latest${
                  app.id === 1 ? "Abb" : app.id === 2 ? "Calz" : null
                }.pdf`
              }
            >
              {t("home.download catalog")}
            </a>
          </div>
          <div className={css.themeRow}>
            {app.theme.map((theme) => {
              if (theme.products.length > 0) {
                return (
                  <div key={theme.id} className={css.themeCol}>
                    <div className={css.themeContent}>
                      {theme.products.slice(0, 4).map((prod) => (
                        <Link
                          key={prod.id}
                          to={`/product/${prod.url}`}
                          className={css.prodWrap}
                        >
                          <img src={path + prod.image} alt="" />
                          <div className={css.prodCode}>{prod.code}</div>
                          <div className={css.prodName}>{prod.name}</div>
                        </Link>
                      ))}
                    </div>
                    <Link
                      to={{
                        pathname: "/search",
                        query: { applicationId: app.id, moodId: theme.id },
                      }}
                    >
                      <h6>{theme.name}</h6>
                    </Link>
                  </div>
                );
              }
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

export default HomeProducts;
